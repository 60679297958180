.footer {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
    /* width: 100%; */
  }
  
  .footer ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer li {
    display: inline-block;
    margin: 0 10px;
  }
  
  .footer a {
    text-decoration: none;
    color: #333;
  }