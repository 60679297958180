.banner-container {
    background-image: url('../../Assets/banner-construction.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
  }
  
  .brand-name {
    position: absolute;
    visibility: hidden;
    top: 20px;
    left: 20px;
    font-size: 32px;
    color: #dcfd1f;
    font-style: italic;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
  }

  .brand-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 200px;
    width: 200px;
    @media only screen and (max-width: 450px) {
      height: 100px;
      width: 100px;
    }
  }

  .banner-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    display: inline-block;
    font-size: 16px;
    margin: 4px 4px;
    cursor: pointer;
  }
