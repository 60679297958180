.contact {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  position: relative;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.reverse {
  flex-direction: row-reverse;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.contact-image {
  flex: 1;
  margin-right: 20px;
}

.contact-image img {
  width: 80%;
  height: auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.contact-title {
  margin-bottom: 0;
}

.contact-discription {
  margin-top: 0;
  margin-bottom: 0;
}

.contact-content {
  flex: 2;
}

.floating-element {
  position: absolute;
  z-index: 2;
  bottom: 16px;
}