.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Optional: Center the cards horizontally */
  }

.card {
    /* width: 100%; */
    position: relative;
    max-width: 250px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    text-align: center;
  }
  
  .card img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .card h3 {
    position: absolute;
    width: 88%;
    top: 150px; /* Adjust this value as needed */
    z-index: 1; /* Ensure title is above the image */
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    padding: 5px 0;
    /* border-radius: 5px; */
  }