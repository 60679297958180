form {
    max-width: 400px;
    margin: 0 auto;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    @media only screen and (max-width: 450px) {
      width: 90%;
    }
  }
  
  textarea {
    resize: vertical;
  }
  
  button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }